<template>
  <div v-if="visible">
    <el-dialog v-ismove :visible="true" :title="type==='add' ? '新增Action' : '编辑Action'" width="600px" :close-on-click-modal="false" :before-close="closeTab">
      <el-form ref="formData" :model="formData" :rules="rules" :inline="false" size="small" label-width="100px">
        <el-form-item label="所属系统">
          <el-input v-model="system" disabled style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="模块名称" prop="uraModule">
          <el-input v-model="formData.uraModule" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="标题" prop="uraTitle">
          <el-input v-model="formData.uraTitle" clearable style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="方法名" prop="uraAction">
          <el-input v-model="formData.uraAction" clearable style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="控制器" prop="uraController">
          <el-input v-model="formData.uraController" clearable style="width: 300px"></el-input>
        </el-form-item>
        <!-- 如果原先有值，则不可修改，无值则可以添加 -->
        <!-- <el-form-item label="Tag" prop="tag">
          <el-input v-model="formData.tag" clearable style="width: 300px" :disabled="!canEdit"></el-input>
        </el-form-item> -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="请求类型" prop="method">
              <el-select v-model="formData.method" size="small">
                <el-option v-for="item in methodList" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态" prop="uraStatus" label-width="60px">
              <el-tooltip effect="dark" placement="top" :content="formData.uraStatus==='1'?'启用':'禁用'">
                <el-switch v-model="formData.uraStatus" size="small" active-value="1"
                  inactive-value="0" active-color="#13ce66" inactive-color="#ff4949">
                </el-switch>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="公共Action?" prop="isPublic">
          <el-switch v-model="formData.isPublic" size="small" :active-value="1"
            :inactive-value="0" active-color="#13ce66"
            inactive-color="#ff4949"
            active
            :active-text="formData.isPublic ? '公共action为系统默认自带' : ''">
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="closeTab">取消</el-button>
        <el-button type="primary" size="small" @click="submit" :loading="waiting">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as validFn from '@/utils/validate'
import { addAction, updateAction } from '@/api/url-config'
import { deepClone } from '@/utils/index'
export default {
  data() {
    return {
      formData: {
        uraAction: '',
        uraController: '',
        uraModule: '',
        uraTitle: '',
        uraId: null,
        method: 'post',
        uraStatus: '1',
        isPublic: 0
      },
      methodList: ['post', 'get', 'put', 'delete'],
      rules: {
        uraModule: [validFn.required()],
        uraTitle: [validFn.required()],
        uraAction: [validFn.required()],
        uraController: [validFn.required()],
        tag: [validFn.enPoint()]
      },
      // canEdit: true,
      waiting: false
    }
  },
  props: {
    form: {
      type: Object,
      default: function() {
        return {}
      }
    },
    visible: {
      type: Boolean,
      defaule: false
    },
    type: {
      type: String,
      default: 'add'
    },
    system: {
      type: String,
      default: ''
    },
    systemId: {
      type: Number,
      default: null
    }
  },
  watch: {
    visible(val) {
      if (val) {
        console.log(this.form)
        console.log(this.systemId)
        Object.keys(this.formData).forEach(key => {
          this.formData[key] = this.form[key]
        })
        this.formData.usId = this.systemId
      }
    }
  },
  methods: {
    closeTab() {
      this.$refs.formData.resetFields()
      this.$emit('update:visible', false)
    },
    submit() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          console.log(this.formData)
          const params = deepClone(this.formData)
          if (this.type === 'add') {
            this.waiting = true
            delete params.uraId
            addAction(params).then(res => {
              if (res.code === '000000') {
                console.log(res)
                this.$message.success(res.message)
                this.closeTab()
                this.$emit('success')
              }
              this.waiting = false
            }).catch(err => {
              console.log(err)
              this.waiting = false
            })
          } else if (this.type === 'edit') {
            this.waiting = true
            updateAction(params).then(res => {
              if (res.code === '000000') {
                console.log(res)
                this.$message.success(res.message)
                this.closeTab()
                this.$emit('success')
              }
              this.waiting = false
            }).catch(err => {
              console.log(err)
              this.waiting = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
