<template>
  <div class="app-container" style="height: 98%; overflow: auto;">
    <el-row type="flex" align="middle" class="top-form">
      <el-form :model="searchForm" :rules="rules" size="small" inline>
        <el-form-item label="系统" prop="system">
          <el-select v-model="searchForm.system" value-key="usId" placeholder="请选择系统" filterable>
            <el-option v-for="item in systemList" :key="item.usId" :value="item" :label="item.usName"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <section style="margin-left: 8px;" v-if="active == 1">
        <!-- 导入导出 -->
        <el-button type="primary" icon="el-icon-folder" v-permission="menuImport.importActions" size="mini" @click="dialogVisible = true">导入</el-button>
        <el-button type="primary" icon="el-icon-download" v-permission="menuImport.exportActions" size="mini" @click="exportActions">导出</el-button>
      </section>
    </el-row>
    <el-row style="border-bottom: 1px solid #dcdfe6">
      <el-button :type="active==='1' ? 'primary' : 'info'" size="mini" @click="handleChange('1')">权限配置</el-button>
      <el-button :type="active==='2' ? 'primary' : 'info'" size="mini" @click="handleChange('2')" style="margin-left:0">Actions</el-button>
    </el-row>
    <template>
      <component @closeRefresh="closeRefresh" :refresh="refresh" :is="current" :msg="systemMsg"></component>
    </template>
    <import-file :type="importType" @import-success="importSuccess" :activeId="searchForm.system.usId" :title="importTitle" :dialogVisible="dialogVisible" @close="dialogVisible=false"></import-file>
  </div>
</template>
<script>
import * as validFn from '@/utils/validate'
import list from './components/listActions'
import tree from './components/treeActions'
import { allSystem } from '@/api/account'
import { exportExcel} from '@/utils/index'
import { exportActionsApi } from '@/api/privilegeImport' 
import permission from '@/utils/permissionsData'
export default {
  components: { tree, list },
  data() {
    return {
      searchForm: {
        system: ''
      },
      rules: {
        system: [validFn.requiredC()]
      },
      systemList: [],
      active: '1',
      current: 'tree',
      systemMsg: {
        system: '',
        systemId: null
      },
      dialogVisible: false,
      importTitle: '权限Actions导入',
      importType: 'sysActions',
      refresh: false,
      menuImport: permission.menuImport
    }
  },
  watch: {
    'searchForm.system': function(val) {
      if (val) {
        this.getData()
        this.systemMsg = {
          system: val.usName,
          systemId: val.usId
        }
      }
    }
  },
  mounted() {
    this.getSystem()
  },
  methods: {
    getSystem() {
      allSystem().then(res => {
        if (res.code === '000000') {
          this.systemList = res.data.list
        }
      }).catch()
    },
    getData() {
      // 触发对应的组件更新数据
      console.log(this.searchForm.system)
    },
    handleChange(item) {
      this.active = item
      const typeMap = new Map([
        ['1', 'tree'],
        ['2', 'list']
      ])
      this.current = typeMap.get(item)
    },
    async exportActions(){
      try {
        const params = this.searchForm.system ? {usIdList: this.searchForm.system.usId } : {}
        const res = await exportActionsApi(params)
        exportExcel(res.data, '权限actions')
      } catch (error) {
        console.error(error);
      }
    },
    importSuccess(){
      this.dialogVisible = false
      this.refresh = true
    },
    closeRefresh(){
      this.refresh = false
    }
  }
}
</script>
<style lang="scss" scoped>
.panels {
  .el-menu-item {
    height: 40px;
    line-height: 40px;
  }
}
.top-form{
  /deep/ .el-form-item {
    margin-bottom: 0px;
  }
  margin-bottom: 12px;
}
</style>

