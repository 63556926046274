<template>
  <!-- 公共Actions 不需要控制权限，所有用户登录系统默认自带 -->
  <div style="padding: 10px 0; height: calc(100% - 80px);position:relative">
    <el-form inline size="small">
      <el-form-item label="菜单权限">
        <el-input v-model="umTitle" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-button size="small" type="primary" @click="getMenuTree">查询</el-button>
    </el-form>
    <el-table
      v-loading="loading"
      :data="treeData"
      :span-method="arraySpanMethod"
      row-key="treeId"
      border
      size="mini"
      default-expand-all
      :header-cell-style="{background: '#41a1ff', color: '#ffffff'}"
      :tree-props="{children: 'childUserMenu', hasChildren: true}">
      <el-table-column label="菜单权限" prop="treeName"></el-table-column>
      <el-table-column label="权限详情" prop="uraAction">
        <template slot-scope="scope">
          <!-- 给页面绑定action 必须要有，是绑定的关联关系，可修改 -->
          <span v-if="!scope.row.page">
            <!-- {{ scope.row.uraAction }} {{ scope.row.method }} -->
            <template v-if="scope.row.childUserRightActionVo && scope.row.childUserRightActionVo.length">
              <el-row :gutter="20" v-for="(action, index) in scope.row.childUserRightActionVo" :key="action.uraId" :class="{'borderBottom' :index < scope.row.childUserRightActionVo.length-1 }">
                <el-col :span="18">{{action.uraController}}{{action.uraAction}}</el-col>
                <el-col :span="6">{{action.method}}</el-col>
              </el-row>
            </template>
          </span>
          <!-- 要区分page页是否是公共权限 -->
          <div v-else>
            <template v-if="scope.row.isPublic">
              <p style="margin:0">{{scope.row.description}}</p>
              <p class="clearfix" style="margin-bottom:0" v-if="scope.row.children.length">
                <span class="floatL" style="margin: 0 10px 5px 0" v-for="item in scope.row.children" :key="item.umId">
                  <el-tag size="small" effect="dark">{{item.uraController}}{{item.uraAction}}</el-tag>
                </span>
              </p>
            </template>
            <!-- 如果已绑定主action -->
            <!-- <span v-if="scope.row.uraAction">{{ scope.row.uraAction }}</span> -->
            <!-- <template v-else-if="scope.row.childUserMenu.length"> -->
              <!-- 判断该页面下是否已经含有子action -->
            <!-- <template v-else>
              <el-select v-model="scope.row.bindUrId" size="mini" placeholder="请关联一个主权限" @change="changeMain(scope.row)">
                <el-option v-for="item in scope.row.userRightMenuVoList" :key="item.urId" :label="item.urName" :value="item.urId"></el-option>
              </el-select>
            </template> -->
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Method" prop="method" width="100" align="center"></el-table-column> -->
      <el-table-column label="Tag" prop="tag">
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.isPower ? scope.row.tag : '' }}</span>
        </template> -->
      </el-table-column>
      <!-- <el-table-column label="状态" width="80" align="center">
        <template slot-scope="scope">
          <el-switch
            v-if="scope.row.uraStatus"
            v-model="scope.row.uraStatus"
            active-value="1"
            inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="changeStatus($event, scope.row)">
          </el-switch>
        </template>
      </el-table-column> -->
      <!-- <el-table-column label="使用人" prop="users">
      </el-table-column> -->
      <el-table-column label="操作" width="120" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.power">
            <el-tooltip effect="dark" content="编辑" placement="top">
              <i class="table_icons el-icon-edit" @click="editNode(scope.row)"></i>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top">
              <i class="table_icons el-icon-delete" @click="delNode(scope.row.urId)"></i>
            </el-tooltip>
            <el-tooltip effect="dark" content="关联权限" placement="top">
              <i class="table_icons el-icon-paperclip" @click="related(scope.row.urId)"></i>
            </el-tooltip>
          </template>
          <template v-if="scope.row.page">
            <el-tooltip effect="dark" content="添加" placement="top">
              <i @click="addAction(scope.row)" class="table_icons el-icon-circle-plus-outline"></i>
            </el-tooltip>
          </template>
          <!-- <template v-if="scope.row.page && scope.row.isPublic">
            <el-tooltip effect="dark" :content="isOpen ? '收起' : '查看'" placement="top">
              <span style="color:#409EFF;cursor:pointer" @click="forDetail">
                <svg-icon :icon-class="isOpen ? 'eye' : 'eye-open'"></svg-icon>
              </span>
            </el-tooltip>
          </template> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增权限 -->
    <power-add v-bind.sync="addOption" @success="getMenuTree()"></power-add>
    <!-- 关联权限，一对多 -->
    <relate-panel :visible.sync="relate" :urId="powerId" @success="getMenuTree()"></relate-panel>
    <!-- 编辑权限 -->
    <power-edit v-bind.sync="editOption" @success="getMenuTree()"></power-edit>
    <!-- 公共action 只展示，修改到list中操作 -->
    <el-dialog :visible="useUserVisible" title="使用此权限的用户角色列表" min-height="500px" :close-on-click-modal="false" :before-close="closeUseTab">
      <el-row>
        <el-col :span="11">
          <el-card class="box-card" header="">
            <div slot="header">
              <i class="el-icon-user-solid"></i>
              <span class="text">用户列表</span>
              <el-tag type="danger" effect="plain" size="mini"> {{ useUserList.length }} 人</el-tag>
            </div>
            <div v-if="useUserList.length > 0" class="use-box">
              <div v-for="(item, i) in useUserList" :key="i">
                <span>{{item.name }}</span>
                <span>（{{ item.username }}）</span>
              </div>
            </div>
            <el-empty v-else description="暂无在使用此权限的用户"></el-empty>
          </el-card>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-card class="box-card">
            <div slot="header">
              <i class="el-icon-s-check"></i>
              <span class="text">角色列表</span>
              <el-tag type="danger" effect="plain" size="mini"> {{ useRoleList.length }} 个</el-tag>
            </div>
            <div v-if="useRoleList.length > 0" class="use-box">
              <div v-for="(role, j) in useRoleList" :key="j">
                {{role.name }}
              </div>
            </div>
            <el-empty v-else description="暂无在使用此权限的角色"></el-empty>
          </el-card>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import powerAdd from './powerAdd'
import relatePanel from './relatePanel'
import powerEdit from './powerEdit'
import { actionsTreeData, bindMainPower,actionsTreeDataFilter } from '@/api/menu-manage'
import { editStatus } from '@/api/url-config'
import { deepClone } from '@/utils/index'
import { deleteUrIdData } from '@/api/privilegeManage'
import { Message } from 'element-ui'
export default {
  components: { powerAdd, relatePanel, powerEdit },
  data() {
    return {
      num: Math.random(),
      treeData: [],
      // treeData: [],
      relate: false,
      powerId: null,
      addOption: {
        visible: false,
        umTitle: '',
        umId: null,
        system: '',
        systemId: null
      },
      // system: '',
      // systemId: null,
      editOption: {
        visible: false,
        form: {}
      },
      loading: false,
      useUserVisible: false,
      useUserList: [],
      useRoleList: [],
      umTitle: '',
    }
  },
  props: {
    msg: {
      type: Object,
      default: () => {
        return {}
      }
    },
    refresh: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    msg(val) {
      // this.system = val.system
      // this.systemId = val.systemId
      this.getMenuTree()
    },
    refresh(val) {
      if (val) {
        this.getMenuTree()
      }
    }
  },
  mounted() {
    this.getMenuTree()
  },
  methods: {
    // 获取菜单（需提供新接口，菜单和action整合在一起）
    getMenuTree() {
      if (!this.msg.systemId) return
      this.loading = true
      this.treeData = []
      actionsTreeDataFilter({
        usId: this.msg.systemId,
        umTitle: this.umTitle
      }).then(res => {
        // 将公共action并入treeData
        if (res.code === '000000' && res.data) {
          const publicData = {
            // usId: 101,
            treeName: '公共权限',
            uraAction: '',
            tag: '',
            status: 1,
            description: '该权限为系统默认权限，在Actions面板中操作',
            users: '',
            page: true,
            isPublic: true,
            children: res.data.publicUserRightAction
          }
          const all = []
          all.push(publicData)
          // 树形数据需要处理字段，userRightMenuVoList =>  childUserMenu 权限转菜单，渲染树形
          const resource = deepClone(res.data.userMenuTree)
          this.handleData(resource)
          console.log('处理后的数据')
          // console.log(resource)
          this.treeData = all.concat(resource)
          console.log(this.treeData)
        }
        this.loading = false
        this.$emit('closeRefresh')
      }).catch(() => {
        this.treeData = []
        this.loading = false
      })
    },
    related(id) {
      console.log(id)
      this.powerId = id
      this.relate = true
    },
    addAction(item) {
      console.log(item)
      this.addOption = {
        visible: true,
        umTitle: item.umTitle,
        umId: item.umId,
        system: this.msg.system,
        systemId: this.msg.systemId
      }
    },
    changeStatus(val, data) {
      const str = val === '1' ? '将权限状态修改为启用？' : '将权限状态修改为禁用？'
      this.$confirm(str, '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        const params = {
          // uraId: data.uraId,
          uraStatusEnum: val
        }
        editStatus(params).then(res => {
          if (res.code === '000000') {
            this.$message.success(res.message)
          }
        }).catch()
      }).catch(() => {
        data.uraStatus = val === '1' ? '0' : '1'
      })
    },
    delNode(id) {
      this.$confirm('提示', '确定删除该条数据？', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        deleteUrIdData(id).then(res => {
          if (res.code === '000000') {
            this.$message.success(res.message)
            this.getMenuTree()
          } else {
            Message({
              message: res.message,
              type: 'error',
              duration: 5 * 1000
            })
            this.useUserList = res.data ? res.data.filter(item => item.username !== '角色名字') : []
            this.useRoleList = res.data ? res.data.filter(item => item.username === '角色名字') : []
            this.useUserVisible = true
          }
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message.info('已取消！')
      })
    },
    closeUseTab() {
      this.useUserVisible = false
      this.useUserList = []
      this.useRoleList = []
    },
    editNode(item) {
      console.log(item)
      this.editOption = {
        visible: true,
        form: {
          // umTitle: item.umTitle,
          systemId: this.msg.systemId,
          urName: item.urName,
          umId: item.umId,
          urId: item.urId,
          tag: item.tag
          // uraStatus: item.uraStatus
          // childrens: item.childrens
        }
      }
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        if (columnIndex === 1) {
          return [1, 5]
        } else if (columnIndex > 1) {
          return [0, 0]
        }
      }
    },
    // 递归处理树形数据
    handleData(data) {
      data.forEach(item => {
        if (item.childUserMenu.length > 0) {
          this.handleData(item.childUserMenu)
        } else {
          if (item.userRightMenuVoList && item.userRightMenuVoList.length > 0) {
            item.childUserMenu = item.userRightMenuVoList
          }
        }
      })
    },
    // forDetail() {
    //   this.isOpen = !this.isOpen
    // }
    // 绑定主权限
    changeMain(item) {
      console.log(item)
      const params = {
        umId: item.umId,
        urId: item.bindUrId,
        usId: this.msg.systemId
      }
      bindMainPower(params).then(res => {
        if (res.code === '000000') {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
          item.bindUrId = null
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
<style lang="scss">
.borderBottom {
  border-bottom: 1px solid #EBEEF5;
}
.use-box {
  line-height: 30px;
  font-size: 14px;
  height: 300px;
  overflow: auto;
}
  .text {
    padding: 0 5px;
  }
</style>

