<template>
  <div v-if="visible">
    <el-dialog v-ismove :visible="true" title="关联权限" width="950px" :close-on-click-modal="false" :before-close="closeTab">
      <!-- 全选按钮 -->
      <el-checkbox v-if="powerList && powerList.length > 0" style="margin-left: 15px;" :indeterminate="isAllIndeterminate"
        v-model="checkAll" @change="handleCheckAllClick">全选</el-checkbox>
      <el-row v-for="(item, index) in powerList" :key="index" class="checkbox_row">
        <el-checkbox
          :indeterminate="item.isIndeterminate"
          v-model="item.isAllChoose"
          @change="handleCheckAllChange(index, item.permissionId, $event)">
          模块名：{{ item.uraModule }}
        </el-checkbox>
        <div style="margin: 10px 0;"></div>
        <el-checkbox
          v-for="check in item.actions"
          :key="check.permissionId"
          v-model="check.flag"
          :true-label="1"
          :false-label="0"
          style="min-width:250px"
          :title="check.uraController+''+check.uraAction"
          @change="handleCheckPerChange(index, item.permissionId, check.permissionId, $event)">
          {{ check.uraTitle }}
        </el-checkbox>
      </el-row>
      <div slot="footer">
        <el-button size="small" @click="closeTab">取消</el-button>
        <el-button type="primary" size="small" @click="submit" :loading="loading" :disabled="loading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getGongnengData, submitGongnengData } from '@/api/privilegeManage'
export default {
  data() {
    return {
      // 自定义全选
      isAllIndeterminate: true,
      checkAll: false,
      powerList: [],
      loading: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    urId: {
      type: Number,
      default: null
    }
  },
  watch: {
    visible(val) {
      if (val && this.urId) {
        this.getPowerData(this.urId)
      }
    }
  },
  mounted() {
    // const arr = options.powerList
    // 接口拿到数据后要处理，判断全选需要的字段需补齐 (等有接口数据再处理默认选中)
    // arr.forEach((item, index) => {
    //   item.permissionId = parseInt(index)
    //   item.isIndeterminate = false // 不确定状态 属性，多选时只选择了一部分时展示的形式
    //   item.isAllChoose = false
    //   item.actions.forEach((el, i) => {
    //     el.permissionId = el.uraId
    //     el.isIndeterminate = false
    //   })
    // })
    // this.powerList = deepClone(arr)
    // this.sourceData = deepClone(arr)
  },
  methods: {
    getPowerData(id) {
      getGongnengData(id).then(res => {
        if (res.code === '000000' && res.data) {
          this.powerList = res.data.map((item, index) => {
            item.permissionId = parseInt(index)
            item.isIndeterminate = false
            item.isAllChoose = true
            if (item.actions) {
              item.actions.forEach(el => {
                el.permissionId = el.uraId
                el.isIndeterminate = false
                if (el.flag === 0) {
                  item.isAllChoose = false
                }
              })
            }
            return item
          })
          console.log(this.powerList)
          // 处理顶部的全选回显
          if (this.powerList && this.powerList.length > 0) {
            const checkList = this.powerList.filter(item => item.isAllChoose === true)
            const indeterminateList = this.powerList.filter(item => item.isIndeterminate !== false || item.isAllChoose === true)
            this.checkAll = this.powerList.length === checkList.length
            this.isAllIndeterminate = this.powerList.length > checkList.length && indeterminateList.length > 0
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    closeTab() {
      this.$emit('update:visible', false)
      this.powerList = []
    },
    handleCheckAllClick(val) {
      this.isAllIndeterminate = false
      this.powerList.forEach(el => {
        el.isAllChoose = val
        if (el.actions && el.actions.length > 0) {
          el.actions.forEach(childItem => { childItem.flag = val ? 1 : 0 })
        }
      })
    },
    handleCheckAllChange(index, id, event) {
      console.log(event)
      this.powerList[index].isAllChoose = event
      if (event === false) {
        this.powerList[index].isIndeterminate = false
      }
      // this.powerList[index].isIndeterminate = false
      const children = this.powerList[index].actions
      if (children) {
        children.forEach(item => {
          item.flag = event ? 1 : 0
        })
      }
      // console.log(this.powerList)
      // 处理顶部的全选回显
      const checkList = this.powerList.filter(item => item.isAllChoose === true)
      this.checkAll = this.powerList.length === checkList.length
      this.isAllIndeterminate = this.powerList.length > checkList.length && checkList.length > 0
    },
    handleCheckPerChange(index, id, childId, event) {
      const children = this.powerList[index].actions
      const len = children.length
      let num_check = 0
      let num_unckeck = 0
      children.forEach(item => {
        if (item.permissionId === childId) {
          item.flag = event
        }
        if (item.flag === 1) {
          num_check++
        } else {
          num_unckeck++
        }
      })
      if (num_check === len) {
        this.powerList[index].isIndeterminate = false
        this.powerList[index].isAllChoose = true
      } else if (num_unckeck === len) {
        this.powerList[index].isIndeterminate = false
        this.powerList[index].isAllChoose = false
      } else {
        this.powerList[index].isIndeterminate = true
        this.powerList[index].isAllChoose = true
      }
      // 处理顶部的全选回显
      const checkList = this.powerList.filter(item => item.isAllChoose === true && item.isIndeterminate === false)
      const indeterminateList = this.powerList.filter(item => item.isIndeterminate !== false || item.isAllChoose === true)
      this.checkAll = this.powerList.length === checkList.length
      this.isAllIndeterminate = this.powerList.length > checkList.length && indeterminateList.length > 0
    },
    submit() {
      console.log(this.powerList)
      // 取到选中的action ID
      const ids = []
      this.powerList.forEach(item => {
        item.actions.forEach(el => {
          if (el.flag === 1) {
            ids.push(el.uraId)
          }
        })
      })
      this.loading = true
      submitGongnengData(this.urId, ids).then(res => {
        if (res.code === '000000') {
          this.$message.success(res.message)
          this.closeTab()
          this.$emit('success')
        }
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.checkbox_row {
  padding: 15px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  margin-bottom: 8px;
}
</style>
