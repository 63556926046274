// URL设置请求
import request from '@/utils/request'

export function actionSelect(reqParams) {
  return request({
    url: '/action/get/page',
    method: 'get',
    params: reqParams
  })
}

export function addAction(reqParams) {
  return request({
    url: '/action/add',
    method: 'post',
    data: reqParams
  })
}

export function deleteAction(reqParams) {
  return request({
    url: '/action/delete',
    method: 'delete',
    data: reqParams
  })
}

export function updateAction(reqParams) {
  return request({
    url: '/action/update',
    method: 'put',
    data: reqParams
  })
}

// 修改URL状态 /action/update/uraId
export function editStatus(reqParams) {
  return request({
    url: '/action/update/uraId',
    method: 'put',
    data: reqParams
  })
}

// 权限action同时增加
export function powerAction(params) {
  return request({
    url: '/userRight/addPowerAndAction',
    method: 'post',
    data: params
  })
}
