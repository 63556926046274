<template>
  <div style="padding: 10px 0; height: calc(100% - 80px)">
    <el-form :model="searchForm" inline size="small">
      <el-form-item label="模块名称">
        <el-input v-model="searchForm.uraModule" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="标题">
        <el-input v-model="searchForm.uraTitle" placeholder="actions标题" clearable></el-input>
      </el-form-item>
      <el-form-item label="控制器">
        <el-input v-model="searchForm.uraController" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="方法名">
        <el-input v-model="searchForm.uraAction" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="所属菜单">
        <el-input v-model="searchForm.umTitle" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-button type="primary" size="small" @click="search">查询</el-button>
      <el-button type="success" size="small" @click="add">新增</el-button>
       <!-- 导入导出 -->
      <el-button type="primary" icon="el-icon-folder" v-permission="menuImport.importActions" size="mini" @click="dialogVisible = true">导入</el-button>
      <el-button type="primary" icon="el-icon-download"  v-permission="menuImport.exportActions" size="mini" @click="exportActions">导出</el-button>
    </el-form>
    <el-table :data="dataList" border size="mini" height="calc(100% - 100px)" :header-cell-style="{background: '#41a1ff', color: '#ffffff'}">
      <el-table-column label="模块名称" prop="uraModule"></el-table-column>
      <el-table-column label="标题" prop="uraTitle"></el-table-column>
      <el-table-column label="控制器" prop="uraController"></el-table-column>
      <el-table-column label="方法名" prop="uraAction"></el-table-column>
      <el-table-column label="公共action？" width="120px" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.isPublic === 0 ? '否' : '是'}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="左侧显示" prop="uraDisplay" width="120px" align="center">
        <template slot-scope="scope">
            <el-switch
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="1"
              inactive-value="0"
              v-model="scope.row.uraDisplay"
              @change="changeUraDisplay($event, scope.row)"
            ></el-switch>
          </template>
      </el-table-column> -->
      <el-table-column label="状态" width="120px" align="center">
        <template slot-scope="scope">
            <el-switch
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="1"
              inactive-value="0"
              v-model="scope.row.uraStatus"
              @change="changeUraStatus($event, scope.row)"
            ></el-switch>
          </template>
      </el-table-column>
      <el-table-column label="所属菜单" prop="umTitle"></el-table-column>
      <el-table-column label="操作" width="120" align="center">
        <template slot-scope="scope">
          <el-tooltip effect="dark" content="编辑" placement="top">
            <i class="table_icons el-icon-edit" @click="editNode(scope.row)"></i>
          </el-tooltip>
          <el-tooltip effect="dark" content="删除" placement="top">
            <i class="table_icons el-icon-delete" @click="delNode(scope.row.uraId)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 1em; text-align: center;">
      <el-pagination
        :page-sizes="[20, 50, 100, 200]"
        :page-size="pageSize"
        :current-page="page"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <!-- 编辑 -->
    <action-edit v-bind.sync="editOption" @success="search"></action-edit>
    <!-- 导入 -->
    <import-file :type="importType" @import-success="importSuccess" :activeId="msg.systemId" :title="importTitle"  :dialogVisible="dialogVisible" @close="dialogVisible=false"></import-file>
  </div>
</template>
<script>
import actionEdit from './actionEdit'
import { actionSelect, editStatus, deleteAction } from '@/api/url-config'
import { cleanParams,exportExcel } from '@/utils/index'
import { exportActionsFile } from '@/api/privilegeImport' 
import permission from '@/utils/permissionsData'
export default {
  components: { actionEdit },
  data() {
    return {
      searchForm: {
        uraTitle: '',
        uraController: '',
        uraAction: '',
        uraModule: '',
        umTitle: ''
      },
      dataList: [],
      page: 1,
      pageSize: 20,
      total: 0,
      editOption: {
        system: '',
        systemId: null,
        visible: false,
        form: {}
      },
      dialogVisible: false,
      importTitle: 'Actions导入',
      importType: 'Actions',
      menuImport: permission.menuImport
    }
  },
  props: {
    msg: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  watch: {
    msg(val) {
      this.editOption.system = val.system
      this.editOption.systemId = val.systemId
      this.getData()
    }
  },
  mounted() {
    this.getData()
    // 先选系统后切换面板，不会触发watch赋值，所以需再赋值一次
    this.editOption.system = this.msg.system
    this.editOption.systemId = this.msg.systemId
  },
  methods: {
    search() {
      if (this.page === 1) {
        this.getData()
      } else {
        this.handleCurrentChange(1)
      }
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.getData()
    },
    handleCurrentChange(page) {
      this.page = page
      this.getData()
    },
    getData() {
      console.log(this.searchForm)
      if (!this.msg.systemId) return
      const params = cleanParams(this.searchForm)
      params.pageNum = this.page
      params.pageSize = this.pageSize
      params.usId = this.msg.systemId
      actionSelect(params).then(res => {
        if (res.code === '000000') {
          this.dataList = res.data.list
          this.total = res.data.total
        } else {
          this.dataList = []
          this.total = 0
        }
      }).catch(() => {
        this.dataList = []
        this.total = 0
      })
    },
    // changeUraDisplay(val, item) {
    //   const str = val === '1' ? '修改状态为在左侧显示？' : '修改状态为不在左侧显示？'
    //   this.$confirm(str, '提示', {
    //     type: 'warning',
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消'
    //   }).then(() => {
    //     // 请求
    //   }).catch(() => {
    //     item.uraDisplay = val === '1' ? '0' : '1'
    //   })
    // },
    changeUraStatus(val, item) {
      const str = val === '1' ? '将Actions状态修改为启用？' : '将Action状态修改为禁用？'
      this.$confirm(str, '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        const params = {
          uraId: item.uraId,
          uraStatusEnum: val
        }
        editStatus(params).then(res => {
          if (res.code === '000000') {
            this.$message.success(res.message)
          }
        }).catch()
      }).catch(() => {
        item.uraStatus = val === '1' ? '0' : '1'
      })
    },
    delNode(id) {
      this.$confirm('确定删除该条数据？', '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        console.log(id)
        deleteAction({ uraId: id }).then(res => {
          if (res.code === '000000') {
            this.$message.success('删除成功！')
            this.getData()
          }
        }).catch()
      }).catch(() => {
        this.$message.info('已取消！')
      })
    },
    editNode(item) {
      console.log(item)
      this.editOption.form = {
        system: this.msg.system,
        systemId: this.msg.systemId,
        uraAction: item.uraAction,
        uraController: item.uraController,
        uraModule: item.uraModule,
        uraTitle: item.uraTitle,
        uraId: item.uraId,
        isPublic: item.isPublic,
        uraStatus: item.uraStatus,
        method: item.method
      }
      this.editOption.type = 'edit'
      this.editOption.visible = true
    },
    add() {
      this.editOption.form = {
        system: this.msg.system,
        systemId: this.msg.systemId,
        uraAction: '',
        uraController: '',
        uraModule: '',
        uraTitle: '',
        uraId: null,
        isPublic: 0,
        uraStatus: '1',
        method: ''
      }
      this.editOption.type = 'add'
      this.editOption.visible = true
    },
    async exportActions(){
      try {
        const params = cleanParams(this.searchForm)
        params.pageNum = this.page
        params.pageSize = this.pageSize
        params.usId = this.msg.systemId
        const res = await exportActionsFile(params)
        exportExcel(res.data, 'actions')
      } catch (error) {
        console.error(error);
      }
    },
    importSuccess(){
      this.dialogVisible = false
      this.getData()
    },
  }
}
</script>
